<!-- 滑鼠滾動 動畫 -->
<template>
  <div class="mouse">
    <div class="scroller"></div>
  </div>
</template>

<style lang="stylus" scoped>
.mouse
  width 4px
  padding 8px 8px
  height 16px
  border 2px solid #000
  border-radius 24px
  opacity 0.38
  box-sizing content-box
.scroller
  width 4px
  height 10px
  border-radius 25%
  background-color #000
  animation-name scroll
  animation-duration 2s
  animation-timing-function cubic-bezier(0.15, 0.41, 0.69, 0.94)
  animation-iteration-count infinite

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
</style>